import { Route, Routes } from "react-router-dom";
import EventPage from "../pages/EventPage";
import { HomePage } from "../pages/HomePage";
import LoginPage from "../pages/Auth/Login";
import DefaultLayout from "../layout/DefaultLayout";
import ECommerce from "../pages/Dashboard/ECommerce";
import CartPage from "../pages/CartPage";
import ProtectedRoute from "./ProtectedRoute";
import UserList from "../pages/Dashboard/UserList";
import EventList from "../pages/Dashboard/EventList";
import FeesBreakdown from "../pages/FeesBreakdown";

const MainRouter = () => {
  return (
    <Routes>
      {/* Public routes without DefaultLayout */}
      <Route path="/" element={<HomePage />} />
      <Route path="/event/:id" element={<EventPage />} />
      <Route path="/cart/:id" element={<CartPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/feesbreakdown" element={<FeesBreakdown />} />

      {/* Dashboard routes with DefaultLayout */}
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute>
            <DefaultLayout isDashboard>
              <Routes>
                <Route path="" element={<ECommerce />} />
                {/* Add other dashboard routes here */}
                {/* For example: */}
                <Route path="users" element={<UserList />} />
                <Route path="events" element={<EventList />} />
                {/* <Route path="settings" element={<Settings />} /> */}
              </Routes>
            </DefaultLayout>
          </ProtectedRoute>
        }
      />

      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default MainRouter;
