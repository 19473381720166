import React from "react";
import blankTicketImageUrl from "../assets/ticket_template.png";
import { HiUserGroup } from "react-icons/hi2";

interface TicketProps {
  title: string;
  price: string;
  // groupSize: number;
}

const GroupTicket: React.FC<TicketProps> = ({
  title="",
  price ,
  // groupSize = 10,
}) => {
  return (
    <div className="relative w-full max-w-md mx-auto">
      {/* Blank ticket image as background */}
      <img
        src={blankTicketImageUrl}
        alt="Ticket template"
        className="w-full h-auto"
        loading="eager"
      />

      {/* Content overlaid on the image */}
      <div className="absolute inset-0 flex items-center">
        {/* Left side content (dark blue area) */}
        <div className="flex-grow flex flex-col justify-center items-center w-2/3 pl-6 pr-2 sm:pl-8 sm:pr-4">
          <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold text-white text-center overflow-hidden overflow-ellipsis px-2 w-full">
            {title?.length > 40 ? (
              <span title={title}>{title?.slice(0, 40)}...</span>
            ) : (
              title
            )}
          </h2>

          {/* <div className="flex items-center text-white text-xs sm:text-sm md:text-base gap-x-1 sm:gap-x-2">
            <HiUserGroup className="flex-shrink-0" size={16} />
            <span className="whitespace-nowrap">
              {groupSize} Tickets in the Group
            </span>
          </div> */}
        </div>

        {/* Right side content (light blue area) */}
        <div className="w-1/3 flex items-center justify-center pr-4 sm:pr-6">
          <span className="text-lg sm:text-xl md:text-2xl font-bold text-white">
            ${price}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupTicket;
