import React from "react";
import { HiMailOpen } from "react-icons/hi";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventName: string;
  email: string;
  isPaid: boolean;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  eventName,
  email,
  isPaid,
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white rounded-2xl shadow-lg max-w-xl max-h-[max] w-full p-6 text-center"
      >
        {/* Icon */}
        <div className="flex justify-center">
          <div className=" p-4 rounded-full">
            <HiMailOpen className="h-30 w-30 text-[#1080B9]" />
          </div>
        </div>

        {/* Title */}
        <h2 className="text-xl font-semibold mt-4">
          {isPaid ? "Ticket Purchase Success" : "RSVP successful"}
        </h2>

        {/* Description */}
        {isPaid && (
          <p className="text-gray-600 mt-2 max-w-70 mx-auto">
            The Ticket for <span className="font-semibold">{eventName}</span>{" "}
            was sent to
            <span className="font-medium"> {email}</span>
          </p>
        )}

        {!isPaid && (
          <p className="text-gray-600 mt-2 max-w-70 mx-auto">
            Your RSVP for <span className="font-semibold">{eventName}</span> was
            successful! please check your email,
            <span className="font-medium"> {email}</span>
          </p>
        )}

        {/* Help Link */}
        {/* <p className="mt-4 text-sm text-[#1080B9]">
          Did not get your ticket?{" "}
          <a href="#" className=" font-semibold">
            Help
          </a>
        </p> */}

        {/* Close Button */}
        {/* <button
          onClick={onClose}
          className="mt-6 bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Close
        </button> */}
      </div>
    </div>
  );
};

export default SuccessModal;
