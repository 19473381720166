import React, { useEffect, useState } from "react";
import {
  FiSearch,
  FiMoreVertical,
  FiMapPin,
  FiCalendar,
  FiUsers,
} from "react-icons/fi";
import { AiOutlineDollar } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { getAllEventsAction } from "../../redux/events/actions";
import { useDispatch, useSelector } from "react-redux";

interface Location {
  latitude: string;
  longitude: string;
  address?: string;
}

interface Event {
  _id: string;
  title: string;
  description?: string;
  isPaid: boolean;
  backdropImage: string;
  location: Location;
  host: {
    fullNames: string;
    profile: string;
    email: string;
  };
  time: string;
  isChatEnabled: boolean;
  status: "draft" | "open";
  isDeleted: boolean;
}

const EventList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    status: "",
    upcoming: false,
    page: 1,
    limit: 10,
  });
  const [totalPages, setTotalPages] = useState(1);

  const dispatch = useDispatch();
  const storageUser = localStorage.getItem("outAuthUser");
  const parsed = storageUser && JSON.parse(storageUser || "");
  const allEvents = useSelector((state: any) => state.events);

  // Function to construct query string from filters
  const constructQueryString = (filters: any) => {
    const params = new URLSearchParams({
      isDeleted: "false",
      populate: "host",
      ...filters,
      ...(filters.upcoming && { upcoming: "true" }),
      ...(filters.status && { status: filters.status }),
      // ...(filters.search && { search: filters.search }),
    });
    return params.toString();
  };
  // Fetch events based on filters and pagination
  useEffect(() => {
    const queryString = constructQueryString(filters);

    console.log("QUERY:::::::::::::::::;", queryString);

    getAllEventsAction(queryString, parsed.accessToken)(dispatch);
  }, [dispatch, filters, parsed.accessToken]);

  // Handle search input changes
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value || "");
  };

  // Update filters when search term changes
  useEffect(() => {
    if (searchTerm) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        search: searchTerm,
        page: 1, // Reset pagination when search term changes
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        // search: undefined,
        page: 1,
      }));
    }
  }, [searchTerm]);

  // Handle filter changes
  const handleFilterChange = (key: keyof typeof filters, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
      page: 1, // Reset pagination when filters change
    }));
  };

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: newPage,
    }));
  };

  // Extract filtered events and pagination metadata
  const filteredEvents = allEvents?.all?.data || [];
  const currentPage = filters.page;

  // Calculate total pages from API response
  useEffect(() => {
    if (allEvents?.all?.pagination) {
      setTotalPages(allEvents.all.pagination.totalPages);
    }
  }, [allEvents]);

  return (
    <>
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          Event Management
        </h1>
        <div className="flex items-center gap-4">
          <div className="relative flex-1">
            <FiSearch className="absolute left-3 top-3 text-gray-500 dark:text-gray-400" />
            <input
              type="text"
              placeholder="Search events by title"
              className="w-full pl-10 pr-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          {/* <button className="px-4 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600">
            Create Event
          </button> */}
        </div>
      </div>

      {/* Filters */}
      <div className="mb-6">
        <div className="flex items-center gap-4">
          <label>
            Status:
            <select
              value={filters.status}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              className="ml-2 px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
            >
              <option value="">All</option>
              <option value="draft">Draft</option>
              <option value="open">Open</option>
            </select>
          </label>
          <label>
            Upcoming:
            <input
              type="checkbox"
              checked={filters.upcoming}
              onChange={(e) => handleFilterChange("upcoming", e.target.checked)}
              className="ml-2"
            />
          </label>
        </div>
      </div>

      {/* Event List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredEvents.map((event: Event) => (
          <div
            key={event._id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden"
          >
            <img
              src={event.backdropImage}
              alt={event.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <div className="flex justify-between items-start mb-4">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {event.title}
                </h2>
                <span
                  className={`px-2 py-1 text-xs rounded-full ${
                    event.status === "open"
                      ? "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
                      : "bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                  }`}
                >
                  {event.status}
                </span>
              </div>
              <div className="space-y-2">
                <div className="flex items-center text-gray-600 dark:text-gray-300">
                  <FiCalendar className="mr-2" />
                  {new Date(event.time).toLocaleDateString()}
                </div>
                <div className="flex items-center text-gray-600 dark:text-gray-300">
                  <FiMapPin className="mr-2" />
                  {event.location.address || "Location not specified"}
                </div>
                <div className="flex items-center text-gray-600 dark:text-gray-300">
                  <FiUsers className="mr-2" />
                  {`Hosted by ${event?.host?.fullNames} (${event?.host?.email})`}
                </div>
              </div>
              <div className="mt-4 flex items-center gap-2">
                {event.isPaid && (
                  <span className="inline-flex items-center px-2 py-1 rounded-full text-xs bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200">
                    <AiOutlineDollar className="mr-1" />
                    Paid
                  </span>
                )}
                {event.isChatEnabled && (
                  <span className="inline-flex items-center px-2 py-1 rounded-full text-xs bg-purple-100 dark:bg-purple-900 text-purple-800 dark:text-purple-200">
                    <BsChatDots className="mr-1" />
                    Chat Enabled
                  </span>
                )}
                {event.isDeleted && (
                  <span className="px-2 py-1 rounded-full text-xs bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200">
                    Deleted
                  </span>
                )}
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 dark:bg-gray-700 flex justify-end">
              <button
                title="more"
                className="text-gray-400 dark:text-gray-300 hover:text-gray-600 dark:hover:text-gray-100"
              >
                <FiMoreVertical />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="mt-6 flex justify-center">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`mx-1 px-3 py-2 rounded-md ${
              page === currentPage
                ? "bg-blue-600 text-white"
                : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    </>
  );
};

export default EventList;
