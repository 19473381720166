import React from "react";
import logo from "../../assets/logo.png";

const handleDownload = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    // Redirect to Google Play Store
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    // Redirect to Apple App Store
    window.location.href = "https://apps.apple.com/rw/app/out-app/id6482999195";
  } else {
    // Fallback for other devices or unsupported platforms
    alert("This app is only available for Android and iOS devices.");
  }
};

const Header: React.FC = () => {
  return (
    <header className="p-4 sticky top-0 z-10 xbg-white bg-white/30 backdrop-blur-lg">
      <div className="container flex justify-between items-center  mx-auto">
        <img src={logo} className="h-10 md:h-15" alt="logo" />
        <nav className="flex gap-6 text-black ">
          <a
            href="#"
            className="hover:text-gray-800 font-medium text-[#209bc5]"
            onClick={(e) => {
              e.preventDefault();
              handleDownload();
            }}
          >
            Download the App
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
