import { GoClock } from "react-icons/go";
import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventTicketsAction,
  getOneEventAction,
  payAnEventAction,
} from "../redux/events/actions";
import logo from "../assets/logo.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TicketBig from "../components/TicketBig";
import { IoLocationOutline, IoShareSocial } from "react-icons/io5";
import React from "react";
import TermsAndConditionsModal from "../pages/TermsAndConditions";
import ErrorCard from "../components/ErrorCard";
import { myEvents } from "../redux/events";
import { HiInformationCircle } from "react-icons/hi";
import { FaPlus, FaMinus } from "react-icons/fa6";
import GroupTicket from "../components/GroupTicket";

// Custom hook for query params
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// App download handler
const handleDownload = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    window.location.href = "https://apps.apple.com/rw/app/out-app/id6482999195";
  } else {
    alert("This app is only available for Android and iOS devices.");
  }
};

const CART_STORAGE_KEY = "OutAppCart";
const TICKET_COUNT_KEY = "OutAppTicketCount";
const SELECTED_TICKET_KEY = "OutAppSelectedTicket";

const CartPage = () => {
  const { id: eventId = "" } = useParams();
  const events = useSelector((state: any) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  // Parse query params
  const queryIsTermsAndCondition = query.get("isTermsAndCondition") === "true";
  const queryIsModalOpen = query.get("isModalOpen") === "true";

  // State initialization
  const [isSticky, setIsSticky] = useState(false);
  const [ticketCount, setTicketCount] = useState(() => {
    const savedCount = localStorage.getItem(TICKET_COUNT_KEY);
    return savedCount ? parseInt(savedCount, 10) : 1;
  });
  const [isModalOpen, setIsModalOpen] = useState(queryIsModalOpen);
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [isTermsAndCondition, setIsTermsAndCondition] = useState(
    queryIsTermsAndCondition
  );
  const [isInitialized, setIsInitialized] = useState(false);

  // Persist ticket count
  useEffect(() => {
    localStorage.setItem(TICKET_COUNT_KEY, ticketCount.toString());
  }, [ticketCount]);

  // Ticket quantity handlers
  const handleIncrement = () => {
    setTicketCount((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    setTicketCount((prevCount) => (prevCount > 1 ? prevCount - 1 : 1));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber;
    setTicketCount(isNaN(value) || value < 1 ? 1 : value);
  };

  // Modal handlers
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleTnC = () => {
    setAcceptTnC(!acceptTnC);
  };

  const openModal = (termsAndCondition: boolean) => {
    setIsTermsAndCondition(termsAndCondition);
    setIsModalOpen(true);
  };

  // Retrieve and set cart data
  const restoreCartData = async () => {
    try {
      // Restore cart
      const cartData = localStorage.getItem(CART_STORAGE_KEY);
      if (cartData) {
        const parsedCart = JSON.parse(cartData);
        dispatch(myEvents.setCart(parsedCart));

        // If no eventId in URL, redirect to event page
        if (!eventId && parsedCart.eventId) {
          navigate(`/event/${parsedCart.eventId}`);
          return;
        }
      } else if (!events.cart && eventId) {
        // No cart in storage or redux, redirect to event page
        navigate(`/event/${eventId}`);
        return;
      }

      // Restore selected ticket from localStorage if available
      const storedTicket = localStorage.getItem(SELECTED_TICKET_KEY);
      if (storedTicket) {
        dispatch(myEvents.setSelectedTicket(JSON.parse(storedTicket)));
      }
    } catch (error) {
      console.error("Error restoring cart data:", error);
      if (eventId) {
        navigate(`/event/${eventId}`);
      }
    }
  };

  // Initial data loading
  useEffect(() => {
    const fetchInitialData = async () => {
      if (eventId) {
        await getOneEventAction(eventId)(dispatch);
        await getAllEventTicketsAction(eventId)(dispatch);
      }

      await restoreCartData();
      setIsInitialized(true);
    };

    fetchInitialData();
  }, [eventId, dispatch]);

  // Handle ticket selection from available tickets
  useEffect(() => {
    if (
      isInitialized &&
      events.cart?.ticketId &&
      events.tickets &&
      events.tickets?.length > 0
    ) {
      const selectedTicket = events.tickets.find(
        (ticket: any) => ticket._id === events.cart.ticketId
      );

      if (
        selectedTicket &&
        (!events.selectedTicket ||
          events.selectedTicket._id !== selectedTicket._id)
      ) {
        dispatch(myEvents.setSelectedTicket(selectedTicket));
        localStorage.setItem(
          SELECTED_TICKET_KEY,
          JSON.stringify(selectedTicket)
        );
      }
    }
  }, [
    events.cart?.ticketId,
    events.tickets,
    isInitialized,
    dispatch,
    events.selectedTicket,
  ]);

  // Persist cart to localStorage whenever it changes
  useEffect(() => {
    if (events.cart) {
      localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(events.cart));
    }
  }, [events.cart]);

  // Persist selected ticket to localStorage whenever it changes
  useEffect(() => {
    if (events.selectedTicket) {
      localStorage.setItem(
        SELECTED_TICKET_KEY,
        JSON.stringify(events.selectedTicket)
      );
    }
  }, [events.selectedTicket]);

  // Payment submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!events.cart || !events.selectedTicket) {
      console.error("Missing cart or selected ticket data");
      return;
    }

    await payAnEventAction({
      ...events.cart,
      numberOfTickets: ticketCount,
    })(dispatch);
  };

  // Calculate total price
  const totalPrice = events.selectedTicket?.price
    ? (parseFloat(events.selectedTicket.price) * ticketCount).toFixed(2)
    : 0;

  return (
    <div
      className={`xh-screen bg-fixed bg-cover bg-center bg-opacity-40`}
      style={{
        backgroundImage: `url('${
          isSticky && events?.selected?.backdropImage
        }')`,
      }}
    >
      <header className="p-4 sticky top-0 z-10 bg-white/30 backdrop-blur-lg">
        <div className="container flex justify-between items-center mx-auto">
          <img src={logo} className="h-10 md:h-15" alt="logo" />
          <nav className="flex gap-6 text-black">
            <a
              href="/#"
              className="hover:text-gray-800 font-medium text-[#209bc5]"
              onClick={(e) => {
                e.preventDefault();
                handleDownload();
              }}
            >
              Download the App
            </a>
          </nav>
        </div>
      </header>

      <div
        className={`xl:container mx-auto transition-colors duration-500 ${
          isSticky ? "bg-transparent" : "bg-[#fffdfd]"
        } min-h-screen`}
      >
        {/* Event Banner */}
        <div className="xl:container p-7 md:p-10">
          {/* Event Image and Title */}
          <div
            className="relative bg-cover rounded-lg overflow-hidden w-full h-[556px]"
            style={{
              backgroundImage: `url(${events?.selected?.backdropImage})`,
            }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-end p-4 text-white">
              <h1 className="text-2xl md:text-3xl font-bold">
                {events?.selected?.title}
              </h1>
            </div>
            <button
              title="share the event"
              className="absolute top-4 right-4 text-white p-2 rounded-full"
            >
              <IoShareSocial className="h-7 w-7" />
            </button>
          </div>

          {/* Content Section */}
          <div className="mt-8 grid xl:grid-cols-2 gap-6">
            {/* Event Details */}
            <div className="md:w-[calc(100%-100px)]">
              <div>
                <h3 className="text-2xl font-[500px] text-[#013548] mb-1">
                  Event Organizer
                </h3>
                <div className="flex justify-center items-center gap-x-5 w-fit py-4">
                  {/* Profile Image */}
                  <img
                    src={events?.selected?.host?.profile}
                    alt="Organizer"
                    className="w-[81px] h-[81px] rounded-full border-2 border-[#013B4F] overflow-hidden object-cover"
                  />
                  <div className="text-[#013a4f]">
                    {/* Organizer Details */}
                    <h1 className="text-2xl font-semibold">
                      {events?.selected?.host?.fullNames}
                    </h1>
                    <p>{events?.selected?.host?.email}</p>
                  </div>
                </div>

                <h2 className="text-xl font-semibold mb-4">Event Details</h2>
                <div className="bg-[#e7f2f8] rounded-md p-4 space-y-4 border-2 border-[#5eb2ec] border-opacity-45">
                  <div className="flex items-center gap-x-5">
                    <GoClock />
                    <p className="uppercase">
                      {events?.selected?.time
                        ? new Date(events.selected.time).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        : "Time not specified"}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-5">
                    <IoLocationOutline />
                    <p>
                      {events?.selected?.location?.address ||
                        "Location not specified"}
                    </p>
                  </div>

                  <div className="flex items-center gap-x-5">
                    <IoLocationOutline />
                    <p>
                      {events?.selected?.description ||
                        "No additional information provided"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Billing Details */}
            <div className="space-y-10">
              <div>
                <h2 className="text-xl font-semibold mb-4">Billing Details</h2>
                <div>
                  <div className="flex text-[#013A4F] gap-4">
                    <p className="font-bold">First Name:</p>
                    <p className="text-[#1080B9]">
                      {events?.cart?.firstName || "Not provided"}
                    </p>
                  </div>
                  <div className="flex text-[#013A4F] gap-4">
                    <p className="font-bold">Last Name:</p>
                    <p className="text-[#1080B9]">
                      {events?.cart?.lastName || "Not provided"}
                    </p>
                  </div>
                  <div className="flex text-[#013A4F] gap-4">
                    <p className="font-bold">Email Address:</p>
                    <p className="text-[#1080B9]">
                      {events?.cart?.email || "Not provided"}
                    </p>
                  </div>
                </div>
              </div>

              {/* Ticket Details */}
              <div>
                <h2 className="text-xl font-semibold mb-4">Ticket Details</h2>
                <p className="text-gray-600 mb-4">
                  {events.selectedTicket?.description ||
                    "No ticket description available"}
                </p>

                <div className="flex flex-col gap-y-1 justify-center items-center sm:items-start sm:justify-start">
                  {events.selectedTicket &&
                    events?.selectedTicket?.type !== "group" && (
                      <TicketBig
                        title={events.selectedTicket?.name}
                        // ticketId={ticket?.id}
                        price={events?.selectedTicket?.price}
                        // qrCodeUrl="https://via.placeholder.com/150"
                      />
                    )}

                  {events.selectedTicket &&
                    events?.selectedTicket?.type === "group" && (
                      <GroupTicket
                        groupSize={events.selectedTicket?.subTickets}
                        price={events.selectedTicket?.price}
                        title={events.selectedTicket?.name || ""}
                      />
                    )}

                  {!events.selectedTicket && (
                    <div className="bg-yellow-100 p-4 rounded-md border border-yellow-300">
                      <p className="text-yellow-700">
                        Loading ticket details...
                      </p>
                    </div>
                  )}

                  <div className="flex mx-auto items-center gap-x-2 text-[#FF7F78] mb-2">
                    <HiInformationCircle className="h-7 w-7" />
                    <p>This Ticket doesn't refund</p>
                  </div>
                </div>

                {events.selectedTicket && (
                  <div className="flex items-center justify-between mt-7">
                    <div className="flex items-center">
                      <div className="flex items-center space-x-1 bg-white dark:bg-gray-700 rounded-lg p-1 shadow-sm">
                        <button
                          onClick={handleDecrement}
                          className="w-8 h-8 flex items-center justify-center rounded-md bg-blue-100 hover:bg-blue-200 text-blue-700 dark:bg-blue-800 dark:hover:bg-blue-700 dark:text-white transition-all duration-200"
                          aria-label="Decrease quantity"
                        >
                          <FaMinus size={16} />
                        </button>

                        <div className="relative w-12">
                          <input
                            title="ticketCount"
                            type="number"
                            className="w-full bg-transparent border-0 text-center text-gray-800 dark:text-white font-medium text-lg focus:ring-0 focus:outline-none appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none p-0 m-0"
                            value={ticketCount}
                            required
                            min={1}
                            onChange={handleInputChange}
                            style={{
                              WebkitAppearance: "none",
                              MozAppearance: "textfield",
                            }}
                          />
                        </div>

                        <button
                          onClick={handleIncrement}
                          className="w-8 h-8 flex items-center justify-center rounded-md bg-blue-100 hover:bg-blue-200 text-blue-700 dark:bg-blue-800 dark:hover:bg-blue-700 dark:text-white transition-all duration-200"
                          aria-label="Increase quantity"
                        >
                          <FaPlus size={16} />
                        </button>
                      </div>
                    </div>

                    <div className="text-right">
                      <p className="font-bold text-xl uppercase dark:text-blue-300">
                        {events.selectedTicket.currency} {totalPrice}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {/* Payment Details */}
              <div>
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <h2 className="text-xl font-semibold">Payment Details</h2>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between">
                  <p>Total:</p>
                  <p className="font-semibold uppercase">
                    {events.selectedTicket?.price &&
                    events.selectedTicket?.currency
                      ? `${events.selectedTicket.currency} ${totalPrice}`
                      : "Price not available"}
                  </p>
                </div>
                <div className="mt-4 items-center">
                  <label className="flex items-start justify-center space-x-4 py-3">
                    <input
                      type="radio"
                      className="form-checkbox"
                      checked={acceptTnC}
                      onChange={toggleTnC}
                    />
                    <span className="-mt-1">
                      By Clicking this you're agreeing to our{" "}
                      <span
                        className="text-blue-500 m-0 cursor-pointer"
                        onClick={() => openModal(true)}
                      >
                        Terms & Policies
                      </span>
                    </span>
                  </label>
                  <div className="flex w-full mb-5">
                    <button
                      disabled={!acceptTnC || !events.selectedTicket}
                      onClick={handleSubmit}
                      className={`mt-4 px-6 py-2 w-full max-w-80 mx-auto ${
                        !acceptTnC || !events.selectedTicket
                          ? "bg-blue-300"
                          : "bg-[#1080B9]"
                      } text-white rounded-md ${
                        !acceptTnC || !events.selectedTicket
                          ? ""
                          : "hover:bg-blue-600"
                      }`}
                    >
                      {events.isLoading
                        ? "Redirecting, please wait..."
                        : "Proceed to Payment"}
                    </button>
                  </div>
                </div>
                {events.paidEvent?.status && (
                  <ErrorCard
                    message={events.paidEvent?.message}
                    status={events.paidEvent?.status}
                    onClose={() => {
                      dispatch(myEvents.setPaidEvent(null));
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TermsAndConditionsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        isTermsAndCondition={isTermsAndCondition}
      />
    </div>
  );
};

export default CartPage;
