import React, { useEffect, useState } from "react";
import { FiSearch, FiMoreVertical } from "react-icons/fi";
import { FaGoogle, FaApple, FaFacebook } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { getAllUsersAction } from "../../redux/users/actions";
import { useDispatch, useSelector } from "react-redux";

interface User {
  accountId: string;
  fullNames: string;
  email: string;
  phone: string;
  loginMethod: "google" | "apple" | "facebook" | "normal";
  profile: string;
  isAdmin: boolean;
  isVerified: boolean;
  isDeleted: boolean;
  createdAt: string;
}

interface UserFilters {
  isVerified: string;
  isAdmin: string;
  loginMethod: string;
  isDeleted: string;
  page: number;
  limit: number;
}

const UserList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<UserFilters>({
    isVerified: "",
    isAdmin: "",
    loginMethod: "",
    isDeleted: "false", // Default to not showing deleted users
    page: 1,
    limit: 10,
  });
  const [totalPages, setTotalPages] = useState(1);

  const allUsers = useSelector((state: any) => state.users);
  const dispatch = useDispatch();
  const storageUser = localStorage.getItem("outAuthUser");
  const parsed = storageUser && JSON.parse(storageUser || "");

  // Function to construct query string from filters
  const constructQueryString = (filters: any, search?: string) => {
    const params = new URLSearchParams({
      ...filters,
      ...(search && { search }),
      ...(filters.isVerified && { isVerified: filters.isVerified }),
      ...(filters.isAdmin && { isAdmin: filters.isAdmin }),
      ...(filters.loginMethod && { loginMethod: filters.loginMethod }),
    });
    return params.toString();
  };

  // Fetch users based on filters and pagination
  useEffect(() => {
    const queryString = constructQueryString(filters, searchTerm);
    getAllUsersAction(queryString, parsed.accessToken)(dispatch);
  }, [dispatch, filters, searchTerm, parsed.accessToken]);

  // Handle search input changes with debounce
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    // Reset to first page when searching
    setFilters((prev) => ({ ...prev, page: 1 }));
  };

  // Handle filter changes
  const handleFilterChange = (key: keyof UserFilters, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
      page: 1, // Reset pagination when filters change
    }));
  };

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: newPage,
    }));
  };

  // Extract users and pagination metadata
  const users = allUsers.users?.data || [];
  const currentPage = filters.page;

  // Calculate total pages from API response
  useEffect(() => {
    if (allUsers.users?.pagination) {
      setTotalPages(allUsers.users.pagination.totalPages || 1);
    }
  }, [allUsers]);

  const LoginMethodIcon = ({ method }: { method: User["loginMethod"] }) => {
    const icons = {
      google: <FaGoogle className="text-red-600 dark:text-red-400" />,
      apple: <FaApple className="text-gray-800 dark:text-gray-200" />,
      facebook: <FaFacebook className="text-blue-600 dark:text-blue-400" />,
      normal: <AiOutlineCheck className="text-green-600 dark:text-green-400" />,
    };

    return icons[method];
  };

  return (
    <>
      <div className="mb-8">
        <h1 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
          User Management
        </h1>
        <div className="flex items-center gap-4">
          <div className="relative flex-1">
            <FiSearch className="absolute left-3 top-3 text-gray-500 dark:text-gray-400" />
            <input
              type="text"
              placeholder="Search by name or email"
              className="w-full pl-10 pr-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <button className="px-4 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600">
            Export Users
          </button>
        </div>
      </div>

      {/* Filters */}
      <div className="mb-6">
        <div className="flex flex-wrap items-center gap-4">
          <label className="flex items-center">
            <span className="mr-2 text-gray-700 dark:text-gray-300">
              Status:
            </span>
            <select
              value={filters.isVerified}
              onChange={(e) => handleFilterChange("isVerified", e.target.value)}
              className="px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
            >
              <option value="">All</option>
              <option value="true">Verified</option>
              <option value="false">Unverified</option>
            </select>
          </label>

          <label className="flex items-center">
            <span className="mr-2 text-gray-700 dark:text-gray-300">Role:</span>
            <select
              value={filters.isAdmin}
              onChange={(e) => handleFilterChange("isAdmin", e.target.value)}
              className="px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
            >
              <option value="">All</option>
              <option value="true">Admin</option>
              <option value="false">User</option>
            </select>
          </label>

          <label className="flex items-center">
            <span className="mr-2 text-gray-700 dark:text-gray-300">
              Login Method:
            </span>
            <select
              value={filters.loginMethod}
              onChange={(e) =>
                handleFilterChange("loginMethod", e.target.value)
              }
              className="px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
            >
              <option value="">All</option>
              <option value="google">Google</option>
              <option value="apple">Apple</option>
              <option value="facebook">Facebook</option>
              <option value="normal">Normal</option>
            </select>
          </label>

          <label className="flex items-center">
            <span className="mr-2 text-gray-700 dark:text-gray-300">
              Show Deleted:
            </span>
            <select
              value={filters.isDeleted}
              onChange={(e) => handleFilterChange("isDeleted", e.target.value)}
              className="px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
              <option value="">All</option>
            </select>
          </label>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                User
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Account ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Contact
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Login Method
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                Join Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {users?.map((user: User) => (
              <tr
                key={user.accountId}
                className="hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <div className="flex items-center gap-3">
                    <img
                      src={user.profile}
                      alt={user.fullNames}
                      className="w-10 h-10 rounded-full"
                    />
                    <div>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {user.fullNames}
                      </div>
                      {user.isAdmin && (
                        <span className="px-2 py-1 text-xs bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-full">
                          Admin
                        </span>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                  {user.accountId}
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900 dark:text-white">
                    {user.email}
                  </div>
                  <div className="text-sm text-gray-500 dark:text-gray-400">
                    {user.phone}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center gap-2">
                    <LoginMethodIcon method={user.loginMethod} />
                    <span className="text-sm text-gray-600 dark:text-gray-300">
                      {user.loginMethod}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex gap-2">
                    {user.isVerified ? (
                      <span className="inline-flex items-center px-2 py-1 rounded-full text-xs bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200">
                        <AiOutlineCheck className="mr-1" />
                        Verified
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2 py-1 rounded-full text-xs bg-gray-100 dark:bg-gray-600 text-gray-800 dark:text-gray-200">
                        <AiOutlineClose className="mr-1" />
                        Unverified
                      </span>
                    )}
                    {user.isDeleted && (
                      <span className="px-2 py-1 rounded-full text-xs bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-200">
                        Deleted
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
                  {new Date(user.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  <button
                    title="more"
                    className="text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-300"
                  >
                    <FiMoreVertical />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-6 flex justify-between items-center">
        <div className="text-sm text-gray-600 dark:text-gray-400">
          Showing page {currentPage} of {totalPages}
        </div>
        <div className="flex">
          <button
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            className={`mx-1 px-3 py-2 rounded-md ${
              currentPage === 1
                ? "bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600"
            }`}
          >
            Previous
          </button>

          {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
            // Show pages around current page
            let pageNum: any;
            if (totalPages <= 5) {
              pageNum = i + 1;
            } else if (currentPage <= 3) {
              pageNum = i + 1;
            } else if (currentPage >= totalPages - 2) {
              pageNum = totalPages - 4 + i;
            } else {
              pageNum = currentPage - 2 + i;
            }

            return (
              <button
                key={pageNum}
                onClick={() => handlePageChange(pageNum)}
                className={`mx-1 px-3 py-2 rounded-md ${
                  pageNum === currentPage
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600"
                }`}
              >
                {pageNum}
              </button>
            );
          })}

          <button
            onClick={() =>
              handlePageChange(Math.min(totalPages, currentPage + 1))
            }
            disabled={currentPage === totalPages}
            className={`mx-1 px-3 py-2 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-100 dark:bg-gray-600 text-gray-400 dark:text-gray-500 cursor-not-allowed"
                : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600"
            }`}
          >
            Next
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2 text-sm text-gray-600 dark:text-gray-400">
            Items per page:
          </span>
          <select
            title="items per page"
            value={filters.limit}
            onChange={(e) =>
              handleFilterChange("limit", Number(e.target.value))
            }
            className="px-2 py-1 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default UserList;
