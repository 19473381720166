import { myUsers } from ".";

import { getAllUsersService } from "./services";
export const getAllUsersAction = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myUsers.setIsLoading(true));
      const res = await getAllUsersService(query, token);
      console.log(">>>>!!!>>>::)) ", res?.data, res);
      if (res?.status === "Success") {
        dispatch(myUsers.setUsers(res?.data));
        dispatch(myUsers.setIsLoading(false));
      }
      dispatch(myUsers.setIsLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const rsvpAnEventAction = (data: any) => {
//   return async (dispatch: any) => {
//     try {
//       dispatch(myEvents.setIsLoading(true));
//       const res = await rsvpAnEventService(data);
//       console.log(">>>>!!!>>>::)) ", res?.response?.data);
//       // if (res?.status === "Success") {
//       dispatch(myEvents.setRsvpEvent(res?.data || res?.response?.data));
//       dispatch(myEvents.setIsLoading(false));
//       // }
//       dispatch(myEvents.setIsLoading(false));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };
