import React from "react";
import blankTicketImageUrl from "../assets/group_ticket_template.png";
import { HiUserGroup } from "react-icons/hi2";

interface TicketProps {
  title: string;
  price: string;
  groupSize: number;
}

const GroupTicket: React.FC<TicketProps> = ({
  title = "",
  price,
  groupSize = 1,
}) => {
  return (
    <div className="relative w-full max-w-md mx-auto">
      {/* Blank ticket image as background */}
      <img
        src={blankTicketImageUrl}
        alt="Ticket template"
        className="w-full h-auto"
        loading="eager"
      />

      {/* Content overlaid on the image */}
      <div className="absolute inset-0">
        {/* Left side content container */}
        <div className="absolute left-0 top-0 bottom-0 w-2/3 flex flex-col items-center">
          {/* Middle centered title */}
          <div className="flex items-center justify-center h-full pl-10">
            <h2 className="text-base xsm:text-lg xmd:text-xl xlg:text-2xl font-bold text-white text-center overflow-hidden overflow-ellipsis px-4 w-full max-w-[90%]">
              {title && title?.length > 40 ? (
                <span title={title}>{title?.slice(0, 35)}...</span>
              ) : (
                title
              )}
            </h2>
          </div>

          {/* Bottom centered group size */}
          <div className="absolute bottom-6 mb-3 sm:mb-4 flex items-center justify-center text-white text-xs sm:text-sm md:text-base">
            <HiUserGroup className="flex-shrink-0 mr-1 sm:mr-2" size={16} />
            <span className="whitespace-nowrap">
              {groupSize} Tickets in the Group
            </span>
          </div>
        </div>

        {/* Right side content (light blue area) */}
        <div className="absolute right-0 top-0 bottom-0 w-1/3 flex items-center justify-center">
          <span className="text-lg sm:text-xl md:text-2xl font-bold text-white">
            ${price}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupTicket;
