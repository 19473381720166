// App.js
import { useEffect, useState } from "react";
import OrganizerImage from "../assets/organizer.png";
import Ticket from "../components/Ticket";
import Footer from "../components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEventTicketsAction,
  getOneEventAction,
} from "../redux/events/actions";
import logo from "../assets/logo.png";
import { SlCalender } from "react-icons/sl";
import { GoClock } from "react-icons/go";
import { IoLocationOutline, IoShareSocial } from "react-icons/io5";
import { BookATicketForm } from "../components/forms/BookATicketForm";
import SuccessRSVP from "../components/SuccessRSVP";
import { myEvents } from "../redux/events";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TicketBig from "../components/TicketBig";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import SuccessModal from "../components/modals/SuccessModal";
import { BsPatchCheckFill } from "react-icons/bs";
import TicketSlider from "../components/TicketSlider";
import GroupTicket from "../components/GroupTicket";

const handleDownload = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    // Redirect to Google Play Store
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.outgroupinc.outapp&hl=en";
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    // Redirect to Apple App Store
    window.location.href = "https://apps.apple.com/rw/app/out-app/id6482999195";
  } else {
    // Fallback for other devices or unsupported platforms
    alert("This app is only available for Android and iOS devices.");
  }
};

const EventPage = () => {
  const eventId: string = useParams()?.id || "";
  const events = useSelector((state: any) => state.events);
  const [isSuccessRSVP, setIsSuccessRSVP] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<string | null>(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const dispatch = useDispatch();

  const [isSticky, setIsSticky] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const payment = searchParams.get("payment"); // "success"
  const qrcode = searchParams.get("qrcode"); // "true"
  const ticketId = searchParams.get("ticketId");
  const email = searchParams.get("email");

  const removeQueryParams = () => {
    setSearchParams({});
  };

  useEffect(() => {
    getOneEventAction(eventId)(dispatch);
    getAllEventTicketsAction(eventId)(dispatch);
  }, [dispatch, eventId]);

  useEffect(() => {
    const handleScroll = () => {
      const backdrop = document.querySelector(".backdrop-container");
      if (backdrop) {
        const { top } = backdrop.getBoundingClientRect();
        setIsSticky(top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleRedirect = () => {
    const redirectLink = `outapp://?share=true&event=${encodeURIComponent(
      JSON.stringify(events.selected)
    )}`;
    console.log(" Redirecting....please wait", events.selected, redirectLink);

    window.location.href = redirectLink;
  };

  const detectDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/android/.test(userAgent)) {
      return "Android";
    }
    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    }
    return "Browser";
  };

  useEffect(() => {
    const device = detectDevice();
    console.log("DEVICE", device, events.selected);

    if (device === "Android" || device === "iOS" /*|| device === "Browser"*/) {
      // Add a 2-second delay before calling handleRedirect
      const delayRedirect = setTimeout(() => {
        handleRedirect();
      }, 2000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(delayRedirect);
    }
  }, [events.selected]);
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    events?.selected?.location?.address
  )}`;

  console.log("Is sticky", isAccordionOpen);

  return (
    <div
      className={`xh-screen bg-fixed bg-cover bg-center bg-opacity-40`}
      style={{
        backgroundImage: `url('${
          isSticky && events?.selected?.backdropImage
        }')`,
      }}
    >
      <header className="p-4 sticky top-0 z-10 xbg-white bg-white/30 backdrop-blur-lg">
        <div className="container flex justify-between items-center  mx-auto">
          <img src={logo} className="h-10 md:h-15" alt="logo" />
          <nav className="flex gap-6 text-black ">
            <a
              href="#"
              className="hover:text-gray-800 font-medium text-[#209bc5]"
              onClick={(e) => {
                e.preventDefault();
                handleDownload();
              }}
            >
              Download the App
            </a>
          </nav>
        </div>
      </header>
      <div
        className={`xl:container max-w-[768px] p-2 mx-auto transition-colors duration-500 ${
          isSticky ? "bg-transparent" : "bg-[#fffdfd]"
        } min-h-screen`}
      >
        {/* Header */}

        {/* Main Content */}
        <main className="p-1 xl:p-8">
          <div
            className={`transition-colors duration-500 ${
              isSticky ? "bg-transparent" : "xbg-white"
            } overflow-hidden`}
          >
            <div
              className={`backdrop-container sticky top-0 xrelative ${
                isSticky ? "xsticky ctop-0 cz-10" : ""
              } rounded-xl overflow-hidden xshadow-lg  mx-auto xborder-2`}
            >
              {/* Background Image */}
              <div
                className={`top-0 sticky w-full h-[556px] bg-cover bg-center transition-opacity duration-300 ${
                  isSticky ? "opacity-0" : "opacity-100"
                }`}
                style={{
                  backgroundImage: `url('${
                    !isSticky && events?.selected?.backdropImage
                  }')`,
                }}
              >
                {/* Dark overlay */}
                {!isSticky && (
                  <div className="absolute inset-0 bg-black bg-opacity-40"></div>
                )}
              </div>

              {/* Text Content */}
              <div className="absolute bottom-5 left-5 text-white">
                <h1 className="text-[48px] font-bold text-white max-w-[748px]">
                  {events?.selected?.title}
                </h1>
                {/* <p className="mt-2 text-lg">Worship night, Vancouver, BC</p> */}
              </div>
              <button
                title="share the event"
                className="absolute top-4 right-4 text-white p-2 rounded-full"
              >
                <IoShareSocial className="h-7 w-7" />
              </button>
            </div>

            <hr className="hidden xl:block" />

            <div className="grid xl:grid-cols-2 gap-y-5 xl:gap-y-0 mt-16">
              <div className="flex bg-white xjustify-center items-center shadow-xl rounded-xl gap-x-5 xxl:w-fit border-r border-gray-200 p-4 xl:w-[calc(100%-100px)]">
                {/* Profile Image */}

                <img
                  src={events?.selected?.host?.profile || OrganizerImage}
                  alt="Organizer"
                  className="w-32 h-32 rounded-full object-cover mb-4"
                />
                <div className="text-[#013a4f]">
                  {/* Organizer Details */}
                  <h1 className="text-2xl font-bold mb-2 ">
                    {events?.selected?.host?.fullNames}
                  </h1>
                  <p>{events?.selected?.host?.email}</p>
                </div>
              </div>

              <div className="bg-[#e7f2f8] border-2 border-[#91C5DF] rounded-lg p-5 w-full xxl:max-w-[478px]">
                {/* <h1 className="text-[48px] font-bold text-black mb-4 max-w-[748px]">
                  {events?.selected?.title}
                </h1> */}
                <div className={`flex justify-between text-black`}>
                  <div className="space-y-2">
                    <div className="flex items-center gap-x-5">
                      <SlCalender />
                      <p>
                        {new Date(events?.selected?.time).toLocaleDateString(
                          "en-US",
                          {
                            weekday: "short",
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-x-5">
                      <GoClock />
                      <p className="uppercase">
                        {new Date(events?.selected?.time).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-x-5">
                      <IoLocationOutline />
                      <a
                        href={googleMapsUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400"
                      >
                        <p>{events?.selected?.location?.address}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid xl:grid-cols-2 gap-y-5 xl:gap-y-0 mt-16">
              {/* Organizer Profile Section */}
              <div className="bg-white rounded-xl p-6 h-fit w-full xl:w-[calc(100%-100px)] border border-black">
                <div className="pb-7">
                  <h2 className="text-2xl text-[#013A4F] font-bold mb-6">
                    Additional Information
                  </h2>
                  <p className="text-gray-400">
                    {events?.selected?.description ||
                      "No additional infomation provided"}
                  </p>
                </div>
              </div>

              {/* Checkout Form Section */}
              <div className="flex bg-white rounded-xl flex-col items-start !w-full xmax-w-screen-sm">
                {events.selected?.isPaid && (
                  <h2 className="text-xl font-bold mb-4 text-[#013A4F] p-5">
                    Available tickets
                  </h2>
                )}
                {events.selected?.isPaid && payment !== "success" && (
                  <>
                    <TicketSlider
                      events={events}
                      selectedTicket={selectedTicket}
                      setSelectedTicket={setSelectedTicket}
                      dispatch={dispatch}
                      myEvents={myEvents}
                      isAccordionOpen={isAccordionOpen}
                      setIsAccordionOpen={setIsAccordionOpen}
                    />
                  </>
                )}

                <div className="xxl:pr-10 mx-auto p-5">
                  {!events?.rsvpEvent?.event && payment !== "success" && (
                    <BookATicketForm
                      onFinish={() => setIsSuccessRSVP(true)}
                      ticketId={selectedTicket}
                    />
                  )}
                  {/* {(events?.rsvpEvent?.event || payment === "success") && (
                    <SuccessRSVP
                      isPaid={events?.selected?.isPaid || false}
                      onDismiss={() => {
                        setIsSuccessRSVP(false);
                        dispatch(myEvents.setRsvpEvent(null));
                        removeQueryParams();
                      }}
                    />
                  )} */}
                </div>
                <SuccessModal
                  email={email || "N/A"}
                  eventName={events?.selected?.title}
                  isOpen={events?.rsvpEvent?.event || payment === "success"}
                  isPaid={events?.selected?.isPaid}
                  onClose={() => {
                    setIsSuccessRSVP(false);
                    dispatch(myEvents.setRsvpEvent(null));
                    removeQueryParams();
                  }}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default EventPage;
