import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myEvents } from "../../redux/events";
import {
  payAnEventAction,
  rsvpAnEventAction,
} from "../../redux/events/actions";
import { FaSpinner } from "react-icons/fa";
import ErrorCard from "../ErrorCard";
import { useLocation, useNavigate } from "react-router-dom";

interface BookATicketFormProps {
  onFinish: () => void;
  ticketId: string | null;
}

export const BookATicketForm: React.FC<BookATicketFormProps> = ({
  onFinish,
  ticketId,
}) => {
  const events = useSelector((state: any) => state.events);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showTicketError, setShowTicketError] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    confirmEmail: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
  });

  const validateFields = (fieldName: string, value: string) => {
    let newErrors = { ...errors };

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (fieldName === "email") {
      if (!value) {
        newErrors.email = "Email is required";
      } else if (!emailRegex.test(value)) {
        newErrors.email = "Please enter a valid email";
      } else {
        newErrors.email = "";
      }

      // Check matching if confirmEmail has a value
      if (formData.confirmEmail && value !== formData.confirmEmail) {
        newErrors.confirmEmail = "Emails do not match";
      } else if (formData.confirmEmail) {
        newErrors.confirmEmail = "";
      }
    }

    if (fieldName === "confirmEmail") {
      if (!value) {
        newErrors.confirmEmail = "Please confirm your email";
      } else if (!emailRegex.test(value)) {
        newErrors.confirmEmail = "Please enter a valid email";
      } else if (value !== formData.email) {
        newErrors.confirmEmail = "Emails do not match";
      } else {
        newErrors.confirmEmail = "";
      }
    }

    setErrors(newErrors);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    console.log("HEEEEEEEEEERRREE", ticketId, events?.selectedTicket?._id);
    setFormData((prev) => ({
      ...prev,
      // phone: events?.selected?.host?.,
      ticketId: ticketId || events?.selectedTicket?._id,
      eventId: events.selected?._id,
      hostId: events.selected?.host?._id,
      [name]: value,
    }));
    validateFields(name, value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!ticketId && events?.selected?.isPaid) {
      setShowTicketError(true);
      return;
    }
    dispatch(myEvents.setIsLoading(true));
    if (events?.selected?.isPaid) {
      console.log("#1... paying...");
      // await payAnEventAction(formData)(dispatch);
      dispatch(
        myEvents.setCart({ ...formData, ticketId: events?.selectedTicket?._id })
      );
      localStorage.setItem(
        "OutAppCart",
        JSON.stringify({ ...formData, ticketId: events?.selectedTicket?._id })
      );
      navigate(`/cart/${events?.selected?._id}`);
    } else {
      console.log("#2... rsvp'ing...", events.selected?._id);
      await rsvpAnEventAction({
        ...formData,
        eventId: events.selected?._id,
      })(dispatch);
    }
    console.log("Form Data:", formData, events);

    if (!events?.selected?.isPaid) {
      // **Append Email as Query in the Current URL**
      const params = new URLSearchParams(location.search);
      params.set("email", formData.email); // Add or update the email in the URL

      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }

    onFinish();
    // onClose(); // Close the modal after submission
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-md font-bold mb-6">
        {`Enter your information before you ${
          events.selected?.isPaid ? "buy" : "RSVP!"
        }`}
      </h2>
      {events.rsvpEvent?.status && (
        <ErrorCard
          message={events.rsvpEvent?.message}
          status={events.rsvpEvent?.status}
        />
      )}

      {events.paidEvent?.status && (
        <ErrorCard
          message={events.paidEvent?.message}
          status={events.paidEvent?.status}
          onClose={() => {
            dispatch(myEvents.setPaidEvent(null));
          }}
        />
      )}
      <div className="space-y-4">
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="First Name"
          className="border px-4 py-3 border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Last Name"
          className="border px-4 py-3 border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        {/* <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          placeholder="Phone Number"
          className="border px-4 py-3 border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        /> */}
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className="border px-4 py-3 border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-500">{errors.email}</p>
        )}

        {events?.selected?.isPaid && (
          <input
            type="email"
            name="confirmEmail"
            value={formData.confirmEmail}
            onChange={handleChange}
            placeholder="Confirm Email"
            className="border px-4 py-3 border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        )}
        {errors.confirmEmail && (
          <p className="mt-1 text-sm text-red-500">{errors.confirmEmail}</p>
        )}
      </div>

      {showTicketError && (
        <ErrorCard
          message={"No Ticket was selected, please select one"}
          status={"Ticket error"}
          onClose={() => {
            dispatch(myEvents.setPaidEvent(null));
            setShowTicketError(false);
          }}
        />
      )}
      <div className="mt-6 flex justify-end space-x-4">
        {/* Submit Button */}
        {events?.selected?.isPaid ? (
          <button
            // type="submit"
            className="w-full bg-[#497dac] text-white text-lg font-semibold py-3 rounded-md hover:bg-blue-700 focus:outline-none"
          >
            {events.isLoading ? "Redirecting...please wait" : "Go to cart"}
          </button>
        ) : (
          <button
            // type="submit"
            className="w-full bg-[#497dac] text-white text-lg font-semibold py-3 rounded-md hover:bg-blue-700 focus:outline-none"
          >
            {events.isLoading ? (
              <>
                please wait...{" "}
                <FaSpinner className="animate-spin inline-block mr-2" />
              </>
            ) : (
              "RSVP Now"
            )}
          </button>
        )}
      </div>
    </form>
  );
};
