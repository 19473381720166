import React from "react";
import ChargeDiagram from "../assets/ChargesDiagram.png";
import Header from "../components/layout/Header";
import Footer from "../components/Footer";

const FeesBreakdown: React.FC = () => {
  const ticketPrice = 15;
  const stripeFee = ticketPrice * 0.029 + 0.3;
  const platformFee = ticketPrice * 0.02;
  const totalDeductions = stripeFee + platformFee;
  const stripeAccountBalance = ticketPrice - totalDeductions;
  const stripePayoutFee = 0.25 + stripeAccountBalance * 0.0025;
  const finalPayout = stripeAccountBalance - stripePayoutFee;

  // Fee percentages
  const stripeFeePercent = 2.9 / 100;
  const stripeFlatFee = 0.3;
  const platformFeePercent = 2 / 100;
  const payoutFeeFlat = 0.25;
  const payoutFeePercent = 0.25 / 100;

  // Calculations
  const stripeBalance = ticketPrice - totalDeductions;

  const payoutFee =
    payoutFeeFlat + Math.round(stripeBalance * payoutFeePercent * 100) / 100;
  const finalEarnings = stripeBalance - payoutFee;

  return (
    <>
      <Header />
      <div className="container mx-auto">
        <div className="max-w-3xl xmx-auto p-6 !text-[#07364E]">
          <h2 className="text-3xl font-semibold mb-9">
            Understanding Platform Charges and Stripe Fees For Event Host in
            OutApp
          </h2>

          <p>
            When organizing an event through OutApp, understanding the breakdown
            of ticket pricing and associated fees is crucial for event hosts.
            This article will explain how platform and Stripe charges are
            applied when a ticket is sold and how much an event host ultimately
            receives in their bank account.
          </p>

          <div className="my-10 ">
            <img src={ChargeDiagram} alt="Event Fees Breakdown" />
          </div>

          <div className="mx-auto rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Breakdown of Fees</h2>
            <p className="mb-8">
              Let’s assume a ticket is priced at $15. Various fees apply before
              the event host receives their earnings:
            </p>

            {/* Payment Processing and Platform Fees */}
            <div className="mb-6">
              <h3 className="text-lg font-bold ">
                1. Payment Processing and Platform Fees
              </h3>
              <p className="text-gray-700 mt-2">
                When a ticket is purchased, the following fees are deducted:
              </p>
              <ul className="list-disc pl-6 mt-2 text-gray-600">
                <li>
                  Stripe Transaction Fee: <strong>2.9%</strong> of the ticket
                  price + <strong>$0.30</strong>
                </li>
                <li>
                  Platform Fee: <strong>2%</strong> of the ticket price
                </li>
              </ul>

              <h4 className="mt-4 font-semibold text-gray-700">Calculation:</h4>
              <ul className="list-disc pl-6 mt-2 text-gray-600">
                <li>
                  Stripe fee: (2.9% of $15) + $0.30 = $0.44 + $0.30 = $0.74
                </li>
                <li>Platform fee: (2% of $15) = $0.30</li>
                <li>Total Deductions: $1.00</li>
              </ul>
              <p className="mt-3 text-gray-800 font-bold">
                Amount received in Stripe: ${stripeBalance.toFixed(2)}
              </p>
            </div>

            {/* Transferring Funds to the Bank */}
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                2. Transferring Funds to the Bank
              </h3>
              <p className="text-gray-700 mt-2">
                To access their earnings, the event host must transfer their
                funds from Stripe to their bank account. Stripe applies a payout
                fee:
              </p>
              <ul className="list-disc pl-6 mt-2 text-gray-600">
                <li>
                  Stripe Payout Fee: <strong>$0.25</strong> +{" "}
                  <strong>0.25%</strong> of the transferred amount
                </li>
              </ul>

              <h4 className="mt-4 font-semibold text-gray-700">Calculation:</h4>
              <ul className="list-disc pl-6 mt-2 text-gray-600">
                <li>
                  0.25% of $14.00 = $0.035 (rounded to $0.05 for simplicity)
                </li>
                <li>Stripe payout fee: $0.25 + $0.05 = $0.30</li>
              </ul>
              <p className="mt-8 text-gray-800">
                After the payout fee is deducted, the event host receives $13.95
                in their bank account.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FeesBreakdown;
