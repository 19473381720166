import { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import TicketBig from "./TicketBig";
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { BsPatchCheckFill } from "react-icons/bs";
import GroupTicket from "./GroupTicket";

const TicketSlider = ({
  events,
  selectedTicket,
  setSelectedTicket,
  dispatch,
  myEvents,
  setIsAccordionOpen,
  isAccordionOpen,
}: any) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: { perView: 1, spacing: 10 },
    breakpoints: {
      //   "(min-width: 640px)": { slides: { perView: 2, spacing: 20 } },
      "(min-width: 1024px)": { slides: { perView: 2, spacing: 20 } },
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  // Function to determine if we're at the last visible slide
  const isLastSlide = () => {
    if (!instanceRef.current) return true;

    const { slides, rel } = instanceRef.current.track.details;

    // Determine the perView value safely
    let perView = 1; // Default to 1

    const slidesOption = instanceRef.current.options.slides;

    // Check if slidesOption exists and is an object with perView property
    if (
      slidesOption &&
      typeof slidesOption === "object" &&
      "perView" in slidesOption
    ) {
      perView = slidesOption.perView as number;
    }

    return rel >= slides.length - perView;
  };

  return (
    <div className="mx-auto px-3 mb-10 w-full max-w-[90vw] xmd:max-w-[80vw] xlg:max-w-[90vw]">
      {/* Slider container with navigation arrows */}
      <div className="relative">
        <div ref={sliderRef} className="keen-slider">
          {events?.tickets.map((ticket: any) => (
            <div
              key={ticket._id}
              className="keen-slider__slide flex justify-center"
            >
              <div
                className={`relative flex flex-col items-center w-full xmax-w-sm xp-4 border rounded-md cursor-pointer transition-shadow hover:shadow-lg
                  ${
                    selectedTicket === ticket?._id
                      ? "border-[#013A4F] bg-[#B5D8E9]"
                      : "border-gray-300"
                  }
                  ${ticket.availableTickets === 0 ? "bg-[#dcdcdc]" : ""}`}
                onClick={() => {
                  if (ticket.availableTickets !== 0) {
                    setSelectedTicket(ticket?._id);
                    dispatch(myEvents.setSelectedTicket(ticket));
                  }
                }}
              >
                {ticket.availableTickets === 0 && (
                  <p className="absolute right-4 top-2 font-semibold uppercase text-[#7e7e7e]">
                    Sold Out
                  </p>
                )}

                {ticket.type !== "group" && (
                  <TicketBig
                    title={ticket?.name || ""}
                    // ticketId={ticket?.id}
                    price={ticket?.price}
                    // qrCodeUrl="https://via.placeholder.com/150"
                  />
                )}

                {ticket.type === "group" && (
                  <GroupTicket
                    groupSize={ticket?.subTickets}
                    price={ticket?.price}
                    title={ticket?.name || ""}
                  />
                )}
                {/* Accordion for Description */}
                <div className="w-full mt-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAccordionOpen(!isAccordionOpen);
                    }}
                    className="flex justify-between items-center w-full px-4"
                  >
                    <span className="font-semibold text-[#013A4F]">
                      Ticket details
                    </span>
                    {isAccordionOpen && selectedTicket === ticket?._id ? (
                      <FiChevronUp className="h-5 w-5" />
                    ) : (
                      <FiChevronDown className="h-5 w-5" />
                    )}
                  </button>
                  {isAccordionOpen && selectedTicket === ticket?._id && (
                    <div className="text-gray-600 p-4">
                      <p>{ticket?.description}</p>
                    </div>
                  )}
                </div>
                {selectedTicket === ticket?._id && (
                  <div className="absolute right-1 top-1">
                    <BsPatchCheckFill className="text-white h-6 w-6" />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Left navigation arrow */}
        {loaded && instanceRef.current && (
          <>
            <button
              title="Previous"
              onClick={(e) => {
                e.stopPropagation();
                instanceRef.current?.prev();
              }}
              disabled={currentSlide === 0}
              className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10 ${
                currentSlide === 0
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              <FiChevronLeft className="h-6 w-6 text-[#013A4F]" />
            </button>

            {/* Right navigation arrow */}
            <button
              title="Next"
              onClick={(e) => {
                e.stopPropagation();
                instanceRef.current?.next();
              }}
              disabled={isLastSlide()}
              className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10 ${
                isLastSlide()
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-100"
              }`}
            >
              <FiChevronRight className="h-6 w-6 text-[#013A4F]" />
            </button>
          </>
        )}
      </div>

      {/* Pagination dots */}
      {loaded && instanceRef.current && (
        <div className="flex justify-center mt-4">
          {[...Array(instanceRef.current.track.details.slides.length)].map(
            (_, idx) => (
              <button
                title="Go to slide"
                key={idx}
                onClick={() => instanceRef.current?.moveToIdx(idx)}
                className={`mx-1 h-2 w-2 rounded-full ${
                  currentSlide === idx ? "bg-[#013A4F]" : "bg-gray-300"
                }`}
              ></button>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default TicketSlider;
