import HttpRequest from "../../utils/HttpRequest";

// const SERVER_URL = "https://api.outapp.app/api/v1";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const submitContactUsService = async (data: any) => {
  return await HttpRequest.post(`${SERVER_URL}/contact-us`, data);
};

export const getOneEventService = async (id: string) => {
  return HttpRequest.get(
    `${SERVER_URL}/events/getById/${id}?populate=host,coHosts`
  );
};

export const rsvpAnEventService = async (data: any) => {
  return await HttpRequest.post(
    `${SERVER_URL}/ticket-bookings/rsvpWithEmail`,
    data
  );
};

export const payAnEventService = async (data: any) => {
  return await HttpRequest.post(
    `${SERVER_URL}/payments/paymentSessionWithEmail`,
    data
  );
};

export const getAllEventTicketsService = async (id: string) => {
  return HttpRequest.get(`${SERVER_URL}/tickets/event/${id}`);
};

export const getAllEventsService = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/events/getAll?${query}`, token);
};
