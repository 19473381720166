// import { FaTwitter } from "react-icons/fa";

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import logo from "../assets/logo.png";
import playStore from "../assets/GET_IT_ON_Google_Play.png";
import appStore from "../assets/Download_on_the_App_Store.png";
import mapIcon from "../assets/map_icon_2.png";
import calendar from "../assets/calendar_icon_2.png";

const Footer = () => {
  return (
    <footer className="bg-white border-t border-gray-200 py-6 mt-6 px-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="hidden">
          <img src={playStore} alt="" />
          <img src={appStore} alt="" />
          <img src={mapIcon} alt="" />
          <img src={calendar} alt="" />
        </div>
        {/* Middle Section */}
        <div>
          <img src={logo} className="h-4 md:h-7" alt="logo" />
        </div>
        <div className="flex justify-center items-center flex-col md:flex-row md:space-x-10">
          <div className="text-sm font-semibold text-gray-600 mt-4 md:mt-0">
            Contact us{" "}
            <a
              href="mailto:hello@outapp.com"
              className="text-[#013A4F] hover:underline font-medium"
            >
              info@outapp.app
            </a>
          </div>

          {/* Right Section */}
          <div className="flex items-center space-x-4 mt-4 md:mt-0">
            <span className="text-sm text-gray-600">Follow Us</span>
            {/* <a href="#" className="text-gray-600 hover:text-blue-500"> */}
            <FaFacebookF />
            {/* </a> */}
            {/* <a href="#" className="text-gray-600 hover:text-blue-500"> */}
            <FaTwitter />
            {/* </a> */}
            {/* <a href="#" className="text-gray-600 hover:text-blue-500"> */}
            <FaInstagram />
            {/* </a> */}
            {/* <a href="#" className="text-gray-600 hover:text-blue-500"> */}
            <FaLinkedinIn />
            {/* </a> */}
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="container mx-auto flex justify-between flex-col items-center md:items-start md:flex-row text-gray-600 text-sm mt-6">
        <div className="flex items-center space-x-6">
          <a href="#" className="text-sm text-gray-600 hover:underline">
            Privacy Policy
          </a>
          <a href="#" className="text-sm text-gray-600 hover:underline">
            Terms of Use
          </a>
        </div>
        <p>
          &copy; 2025. Powered by <span className="font-semibold">OutApp</span>.
          All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
