import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // Check if a token exists in local storage
  const storageUser = localStorage.getItem("outAuthUser");
  const parsed = storageUser && JSON.parse(storageUser || "");
  const token = parsed?.accessToken;

  // If no token is found, redirect to the login page
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // If a token exists, render the children
  return children;
};

export default ProtectedRoute;
